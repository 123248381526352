import React from 'react';

const PurposesList = purposeList => {
    const data = purposeList.purposeList;
    return (
        <>
            <section
                className="page-section bg-secondary centersec"
                id="purposeintro"
            >
                <div className="container smallestwdt">
                    <h5
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: data.play_with_a_purpose_description,
                        }}
                    />
                </div>
            </section>

            <section className="page-section centersec nopaddbottsec">
                <div className="container smallestwdt">
                    <div className="packagesdiv purposeboxes">
                        {data.purpose_list.map((purpose, i, arr) => {
                            let isLast = '';
                            if (arr.length - 1 === i) {
                                isLast = 'lastonebox';
                            }
                            return (
                                <div
                                    className={`pricingbox bg-primary text-white ${isLast}`}
                                    key={purpose.purpose_name}
                                >
                                    <div className="purposelft">
                                        <h2>
                                            <span
                                                className="yellowtxt"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        purpose.purpose_name,
                                                }}
                                            />
                                        </h2>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: purpose.purpose_text,
                                            }}
                                        />
                                    </div>
                                    <img
                                        className="purpkid"
                                        src={
                                            purpose.purpose_kid_image.localFile
                                                .childImageSharp.fluid.src
                                        }
                                        alt="purpose kid"
                                    />
                                    <img
                                        className="purpstar"
                                        src={
                                            purpose.purpose_start_image
                                                .localFile.childImageSharp.fluid
                                                .src
                                        }
                                        alt="purpose star"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </>
    );
};
export default PurposesList;
